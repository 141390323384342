import React from "react";
import { Link } from "react-scroll";
// import { IoLogoGooglePlaystore } from "react-icons/io5";
import { RxHamburgerMenu } from "react-icons/rx";

const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-xl ms-md-5 me-md-5 me-sm-1 ms-sm-1">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          <img src="images/Logo.png" alt="Bootstrap" width="70" height="70" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar"
          aria-controls="offcanvasNavbar"
          aria-label="Toggle navigation"
        >
          <RxHamburgerMenu style={{ color: "#fff", fontSize: 30 }} />
        </button>
        <div
          className="offcanvas offcanvas-end"
          tabIndex="-1"
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
        >
          <div className="offcanvas-header">
            <a className="navbar-brand" href="/">
              <img
                src="images/Logo.png"
                alt="Bootstrap"
                width="70"
                height="70"
              />
            </a>
            <button
              type="button"
              className="btn-close btn-close-white"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body">
            <ul className="navbar-nav me-auto ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link
                  to="mainSec"
                  smooth={true}
                  duration={500}
                  className="nav-link"
                  href="/"
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="workingSec"
                  smooth={true}
                  duration={500}
                  className="nav-link"
                  href="/"
                >
                  Working
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="demoSec"
                  smooth={true}
                  duration={500}
                  className="nav-link"
                  href="/"
                >
                  Demo
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="reviewSec"
                  smooth={true}
                  duration={500}
                  className="nav-link"
                  href="/"
                >
                  Reviews
                </Link>
              </li>
            </ul>
            {/* <button className="btn btnDownload" type="button">
              <IoLogoGooglePlaystore /> Download
            </button> */}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

import React from 'react';

const LandingSec = () => {
  return (
    <>
        <div className='container-fluid landingSec'>
            <div className='row'>
                <div className='col-lg-8 col-md-12 col-sm-12 text-sm-left text-md-left'>
                    <h1>Your Ultimate Sports Booking Companion</h1>
                    <p>The ultimate sports booking app designed to simplify and enhance your experience in finding and booking sports activities.</p>
                    <div className='d-flex downloadPlay mt-5'>
                        <a href="https://play.google.com/store/apps/details?id=com.gencoft.findmecourts&hl=en-IN" target="_blank" rel="noopener noreferrer">
                        <img src="images/googleplay.png" alt="Google Play" className="me-lg-5 me-sm-2 img-fluid" />
                        </a>
                        <a href="https://apps.apple.com/in/app/findmecourts/id6480585243" target="_blank" rel="noopener noreferrer">
                        <img src='images/appstore.png' alt='App Store' className='me-lg-5 me-sm-2 img-fluid' />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default LandingSec
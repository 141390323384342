import React from 'react'

const DemoSec = () => {
  return (
    <>
    <div id="demoSec" className='container-fluid demoSec'>
        <div className='row'>
            <div className='col-lg-12 text-center'>
                <h1>See It in Action</h1>
                <p>Watch a live demonstration of our features.</p>
            </div>
        </div>
    </div>
    </>
  )
}

export default DemoSec
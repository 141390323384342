import React from "react";

const DownloadSec = () => {
  return (
    <>
      <div id="downloadSec" className="container-fluid downloadSec">
      <div className="row">
        <div className="col-lg-12 text-center">
        <h1 className="main-heading">
            Reserve Your <span className="highlight">Court</span> In Just A Few Taps!
          </h1>
          <div className="d-flex downloadPlay mt-5 justify-content-center">
            <a href="https://play.google.com/store/apps/details?id=com.gencoft.findmecourts&hl=en-IN" target="_blank" rel="noopener noreferrer">
            <img
              src="images/googleplay.png"
              alt="Google Play"
              className="me-lg-5 me-sm-2 img-fluid"
            />
            </a>
            <a href="https://apps.apple.com/in/app/findmecourts/id6480585243" target="_blank" rel="noopener noreferrer">
            <img src="images/appstore.png" alt="App Store" className="me-lg-5 me-sm-2 img-fluid" />
            </a>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default DownloadSec;

import React from "react";

const ReviewItem = (props) => {
  return (
    <div className="review-item-container">
      <div className="review-item">
        <img src={props.img} alt="User" className="user-img" />
        <div className="card-content">
          <div className="d-flex justify-content-start align-items-center mt-3 mb-3">
            <h3 className="user-name">{props.name}</h3>
            <div className="rating ms-3">⭐ {props.rating}</div>
          </div>
          <p className="user-review">{props.review}</p>
        </div>
      </div>
    </div>
  );
};

export default ReviewItem;

import React from 'react';

const WorkingSec = () => {
  return (
    <div id="workingSec" className="container-fluid workingSec">
      <div className="row">
          <div className="col-lg-12 text-center">
            <h1>Reserve Your Sport On-the-Go</h1>
            <p>Get ready to play in just 3 easy steps!</p>
          </div>
        </div>
      <div className="row workingSec-con">
        <div className="col-lg-6 text-center">
          <img  src="images/working1.png" className="img-fluid" alt="Step 1" />
        </div>
        <div className="col-lg-6 text-center">
          <img src="images/working2.png" className="img-fluid" alt="Step 1" />
        </div>
        <div className="col-lg-12 text-center">
          <img src="images/working3.png" className="img-fluid" alt="Step 1" />
        </div>
      </div>
    </div>
  );
};

export default WorkingSec;

import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="docs" style={{ padding: "20px", lineHeight: "1.6" }}>
      <h1>Terms & Conditions</h1>
      <h2>STRAWKET LEARNING INC. PLATFORM TERMS OF USE</h2>
      <p>LAST UPDATED: August 27, 2024</p>
      <h2>1. OVERVIEW</h2>
      <p>
        These Terms of Use form a legally binding contract between STRAWKET
        LEARNING INC. ("we" or "us") and the people and companies (“Users” or
        “you”) that access and use our website located at{" "}
        <a href="http://www.findmecourts.com">www.findmecourts.com</a> or any
        related website or mobile platform or mobile app controlled by us
        (collectively, the "Platform").
      </p>
      <p>
        These Terms of Use apply to all services provided through our Platform
        and any other software application we operate; and all content, services
        and products available at or through the Platform.
      </p>
      <p>
        It is very important that you read and fully understand these Terms of
        Use before using the Platform.
      </p>
      <p>
        By agreeing to these Terms of Use and using the Platform, you are
        entering into a legally binding contract which will impact your rights.
        By accessing any part of the Platform, you agree to be bound by these
        Terms of Use. You are also confirming that you have read and fully
        understood these Terms of Use. If you do not agree to or understand all
        of these Terms of Use, then you may not access the Platform or use any
        services available on the Platform.
      </p>
      <p>
        These Terms of Use should be read in conjunction with the Privacy Policy
        and any other rules, guidelines or policies posted on the Platform.
      </p>
      <h2>2. SERVICES AVAILABLE ON THE PLATFORM</h2>
      <p>
        Without restriction, we generally offer the following services through
        the Findmecourts Platform:
      </p>
      <p>
        Facilitate listing of sports facilities by owner of these facilities and
        booking of sports facilities by users
      </p>
      <p>
        The services we offer are subject to change over time. By using the
        Platform, you are confirming that you have determined that the services
        are appropriate for your needs. We do not guarantee that these services
        meet your needs or that they are suitable for your specific purposes.
      </p>
      <h2>3. OWNERSHIP OF YOUR FILES</h2>
      <p>
        When you choose to upload documents or data (including any images or
        text), STRAWKET LEARNING INC. does not review or pre-screen the contents
        of electronic data uploaded or posted to the Platform (“Content”) and
        STRAWKET LEARNING INC. claims no intellectual property rights with
        respect to the Content. However, by posting, uploading, inputting or
        submitting any Content whatsoever to the Platform, you are granting
        STRAWKET LEARNING INC. an irrevocable, royalty-free license while the
        Content remains uploaded to the Platform, to use the Content for any
        purpose related to the use and promotion of its business and the
        Platform, including the right to copy, distribute, edit and publicly
        display such content with or without attribution.
      </p>
      <h2>4. PAYMENT PROCEDURES</h2>
      <p>
        Payments for services or products available on the Platform will be
        charged to you in accordance with the policies, procedures and timelines
        posted on the relevant sections of the Platform. You agree to pay the
        fees applicable to your subscription and any other applicable fees,
        including but not limited to fees relating to the processing of
        transactions under your account (“Fees”).
      </p>
      <p>
        All initial and recurring Fees will be charged to the credit card that
        you authorize for your account. It is your responsibility to keep your
        authorized credit card valid and up to date at all times. We may
        terminate or block access to your account if your credit card becomes
        expired or otherwise invalid at the time any Fees become due.
      </p>
      <p>
        For purposes of processing payments, we use the following service
        provider(s):
      </p>
      <p>
        <strong>STRIPE</strong>
      </p>
      <p>
        Before using the Platform, you must first review and approve the terms
        and conditions governing the use of these third-party payment
        processors, which are available at the following website(s):
        <a href="https://www.stripe.com">https://www.stripe.com</a>
      </p>
      <p>
        You may make In-App purchases through the Platform. If you choose to
        make an In-App purchase, then the App Store Provider's terms of use
        and/or end-user license agreement from which you originally downloaded
        the Platform shall also be applicable. You acknowledge and agree that
        all billing and transaction processes for In-App purchases are governed
        by the App Store Provider from which you originally downloaded the
        Platform.
      </p>
      <h2>5. REFUND, EXCHANGE AND SHIPPING POLICIES</h2>
      <p>
        Generally, once a booking is confirmed, cancellations can be made up to
        24hrs before and refunds will be processed as account credits. However,
        specific exceptions to this policy shall be communicated in-app.
      </p>
      <h2>6. YOUR ACCOUNT</h2>
      <p>
        In order to fully use the Platform, you will be required to create an
        account. Information submitted by you in order to create an account will
        be subject to our Privacy Policy, which is available for review on the
        Platform, and which you are required to approve before creating an
        account. The Privacy Policy also dictates how to terminate your account
        and how you can have your personal identifying information deleted from
        our servers. The terms governing the features and capabilities of your
        account and the related fees can be found on the Platform.
      </p>
      <h2>7. TERMINATION</h2>
      <p>
        You may cancel and terminate your Account at any time in accordance with
        the terms and policies posted on the Platform. If at the date of
        termination of your Account, there are any outstanding payments owing by
        you to us, you will receive one final invoice via email. Once that
        invoice has been paid in full, you will not be charged again.
      </p>
      <h2>8. LIMITATION OF LIABILITY</h2>
      <p>
        Except in a case where we are in violation of these Terms of Use, we
        will not be held liable for, and you hereby fully waive the right to
        claim for, any loss, injury, claim, liability or damages of any kind
        resulting in any way from the use of the Platform. Your use of the
        Platform is at your sole risk. The Platform simply facilitates court booking
        and does not assume any liability whatsoever,especially but not limited to
        any liability resulting from the use of these facilities.
        </p>
        <p>The Platform is provided on an “as is”
        and “as available” basis without any warranty or condition, express,
        implied or statutory. We do not warrant that your use of the Platform
        will be uninterrupted, secure or error-free. In no event will we have
        any liability to you or any third party for any lost profits or revenues
        or for any indirect, special, incidental, consequential or punitive
        damages however caused, whether in contract, tort or otherwise, and
        whether or not you or the third party have been advised of the
        possibility of such damages. In the event the foregoing paragraph or any
        part thereof, is void under applicable law, this paragraph, or such part
        thereof, shall be inapplicable.
      </p>
      <h2>9. INDEMNIFICATION</h2>
      <p>
        You agree to indemnify and hold harmless STRAWKET LEARNING INC.,
        including our officers, directors, shareholders, employees and agents,
        from and against any and all claims and expenses, including legal fees
        and disbursements, which are made against us and arise out of your use
        of the Platform, including but not limited to your violation of any term
        of these Terms of Use or any other policy posted on the Platform.
      </p>
      <h2>10. SECURITY BREACHES</h2>
      <p>
        In order to protect your security, it is your sole responsibility to
        ensure that all usernames and passwords used to access the Platform are
        kept secure and confidential. You must immediately notify us of any
        unauthorized use of your account, including the unauthorized use of your
        password or any other breach of security. We will investigate any breach
        of security on the Platform that we determine in our sole discretion to
        be serious in nature, but we will not be held responsible or liable in
        any manner for breaches of security or any unauthorized access to your
        account however arising.
      </p>
      <h2>11. WARRANTIES AND REPRESENTATIONS</h2>
      <p>
        We hereby disclaim all warranties of any kind, whether express, implied
        or statutory, including but not limited to implied warranties as to
        merchantability or fitness for a particular purpose as they relate to
        the Platform.
      </p>
      <h2>12. COMPLIANCE WITH LAWS</h2>
      <p>You represent and warrant that:</p>
      <p> i. You have the authority to bind yourself to these Terms of Use;</p>
      <p>
        ii. Your use of the Platform will be solely for purposes that are
        permitted by these Terms of Use;
      </p>
      <p>
        iii. Your use of the Platform will not infringe or misappropriate the
        confidentiality or intellectual property rights of any User or third
        party; and
      </p>
      <p>
        iv. Your use of the Platform will comply with all local, provincial and
        federal laws, rules and regulations, and with all policies posted on the
        Platform.
      </p>
      <p>
        You must only use the Platform for your own lawful purposes, in
        accordance with these Terms of Use and any notice, policy or condition
        posted on the Platform. You may use the Platform on behalf of others or
        in order to provide services to others but if you do so you must ensure
        that you are authorized to do so and that all persons for whom or to
        whom services are provided comply with and accept all these Terms of
        Use.
      </p>
      <h2>13. AGE RESTRICTIONS</h2>
      <p>
        Users Must be Over the Age of 18. You represent and confirm that you are
        over the age of 18. We do not target, market or promote the Platform to
        those under 18. We do not permit any User under the age of 18 to use the
        Platform.
      </p>
      <h2>14. GOVERNING LAW AND DISPUTE RESOLUTION</h2>
      <p>
        These Terms of Use and any access to or use of the Platform shall be
        governed by, and construed in accordance with, the laws in force in the
        Province of Ontario. If any claim, dispute or controversy occurs between
        STRAWKET LEARNING INC. and a User relating to the interpretation or
        implementation of any of the provisions of these Terms of Use, such
        dispute shall be resolved by private, confidential and binding
        arbitration. Such arbitration shall be conducted by a single arbitrator.
        The arbitrator shall be appointed by agreement of the parties or, in the
        absence of an agreement, such arbitrator shall be appointed by a judge
        upon the application of either the User or STRAWKET LEARNING INC.
        Arbitration shall be held in the Province of Ontario, unless otherwise
        agreed by the parties. The arbitration procedure to be followed shall be
        agreed by the parties or, in absence of an agreement, determined by the
        arbitrator. The arbitration shall proceed in accordance with the
        provisions of the Arbitration Act, 1991, SO 1991, c 17. Subject to any
        right of appeal, the decision arrived at by the arbitrator shall be
        final and binding. Judgment upon the award rendered by the arbitrator
        may be entered in any court having jurisdiction.
      </p>
      <h2>15. WAIVER OF CLASS ACTION</h2>
      <p>
        By using the Platform, you agree to resolve any claim or dispute arising
        between you and us on an individual basis, rather than addressing such
        claim or dispute as part of a group or class. You hereby waive any right
        you may have to commence or participate in any class action lawsuit
        commenced against STRAWKET LEARNING INC. or its affiliates related to
        any claim, dispute or controversy arising from your use of the Platform.
        Where applicable, you hereby agree to opt out of any class proceeding
        against STRAWKET LEARNING INC. otherwise commenced.
      </p>
      <p>
        The above waiver shall not apply to claims or disputes arising under
        consumer protection legislation or any other claim or dispute where a
        waiver of class action lawsuits is unenforceable at law.
      </p>
      <h2>16. GENERAL TERMS</h2>
      <h3>a. AMENDING THESE TERMS OF USE</h3>
      <p>
        These Terms of Use may be updated and amended from time to time. We
        reserve the right to change these Terms of Use at any time, and any
        amended Terms of Use are effective upon posting to the Platform. We will
        make efforts to communicate any changes to these Terms of Use we deem
        material, in our sole discretion, via email or notifications on the
        Platform. Your continued use of the Platform will be deemed to be
        immediate and unconditional acceptance of any amended Terms of Use,
        whether or not we deemed the amendments to be material.
      </p>
      <h3>b. ASSIGNMENT</h3>
      <p>
        We may assign or delegate these Terms of Use, in whole or in part, to
        any person or entity at any time with or without your consent and
        without prior notice to you. Users may not assign or delegate any rights
        or obligations under these Terms of Use, without our prior written
        consent, and any unauthorized assignment and delegation is void.
      </p>
      <h3>c. NO WAIVER</h3>
      <p>
        No waiver of a provision, right or remedy of this Agreement shall
        operate as a waiver of any other provision, right or remedy or the same
        provision, right or remedy on a future occasion.
      </p>
      <h3>d. NO AGENCY</h3>
      <p>
        The parties to these Terms of Use are independent contractors and are
        not partners or agents. STRAWKET LEARNING INC. has no fiduciary
        obligations or professional obligations whatsoever to you arising from
        these Terms of Use or your use of the Platform.
      </p>
      <h3>e. SEVERABILITY</h3>
      <p>
        In the event that any provision or part of this Agreement is found to be
        void or invalid by a court of law, the remaining provisions, or parts
        thereof, shall be and remain in full force and effect.
      </p>
      <h3>f. ENTIRE AGREEMENT</h3>
      <p>
        These Terms of Use, in conjunction with all policies and guidelines
        available on the Platform (including but not limited to any Privacy
        Policy), incorporated by reference, constitute the entire agreement
        between you and STRAWKET LEARNING INC. and supersede all prior
        communications, agreements and understandings, written or oral, with
        respect to the subject matter of these Terms of Use.
      </p>
      <h2>17. QUESTIONS ON THESE TERMS OF USE</h2>
      <p>
        We welcome you to contact us with any questions on these Terms of Use.
        You can send your questions regarding these Terms of Use to the
        following email address:{" "}
        <a href="mailto:support@strawket.com">support@strawket.com</a>
      </p>
      <h2>18. ADDITIONAL TERMS</h2>
      <p>
        The following additional terms and conditions apply to the use of the
        Platform:
      </p>
      <p>Cancellations shall be subject to individual club policies.</p>
      <h2>19. CONFIRMATION OF AGREEMENT TO TERMS OF USE</h2>
      <p>
        BY PROCEEDING TO USE THE PLATFORM, YOU ACKNOWLEDGE THAT YOU HAVE READ,
        UNDERSTOOD AND AGREE TO BE BOUND BY THESE TERMS OF USE AND ANY POLICIES
        AND NOTICES POSTED ON THE PLATFORM.
      </p>
    </div>
  );
};

export default TermsAndConditions;

import React from 'react'
import ScrollToTop from './ScrollToTop';
import { BrowserRouter as Router, Routes as Switch, Route } from 'react-router-dom';
import Home from './pages/Home';
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';

const Routes = () => {
  return (
    <Router>
        <ScrollToTop />
        <Switch>
            <Route path="/terms" element={<TermsAndConditions/>}/>
            <Route path="/privacy" element={<PrivacyPolicy/>}/>
            <Route path="/" element={<Home/>}/>
        </Switch>
    </Router>
  )
}

export default Routes